import React from "react";
import Header from "../../components/Header";
// import HeaderImage from "../../images/header_bg_1.jpg";
import HeaderImage from "../../images/gallery40.jpg";
// import HeaderImage from "../../images/w7.jpg";

import StoryImage from "../../images/zz.jpg";
// import VisionImage from "../../images/vv2.jpg";
import VisionImage from "../../images/w7.jpg";

// import MissionImage from "../../images/vv.jpg";
import MissionImage from "../../images/f.jpg";
// import MissionImage from '../../images/gallery40.jpg'
import "./about.css";

const About = () => {
  return (
    <>
      <Header title="קצת עלי" image={HeaderImage}>
        <p>
          {/* בית ספר לאגרוף תאילנדי ולאומניות לחימה וקרב מגע, מתאים לכל הגילאים
          בדגש להכנה לתחרויות בארץ ובחוץ לארץ */}
        </p>
      </Header>

      <section className="about__story">
        <div className="container about__story-container">
          <div className="about__section-image">
            <img src={StoryImage} alt="Story" />
          </div>
          <div className="about__section-content">
            <h1> הסיפור שלי </h1>

            <p>
              וויטלי בן 44 בוגר קורסים באומניות הלחימה משולבות בארץ ובחו"ל בעל
              תואר אליפות ישראל ואירופה, מספר שנים החזקתי בתואר מ2001 עד 2004
              בעל חגורה שחורה קיקבוקסינג! בעל חגורה שחורה בקרב מגע, תקטיקה דן 2
              . <br/> שנים רבות מתעסק בתחום אגרוף תאילנדי ובעל תעודה רשמית של MUT בחול
              ובארץ מאושר ע"י מכללת גבעת וושוינגון .
              <p>
                <br /> מדריך ומאמן קרב מגע מאושר ע"י מכללת וינגייט מדריך ומאמן
                בקרב מגע בשיטת MFC משך שנים רבות התעסקתי ביחידות מובחרות בתור
                מדריך לחימה קרב מגע ולוט"ר כמו כן בעל ניסיון רב בתחום הצבאי
                באימון יחדיות מובחרות כמו כן יחידות אבטחה ממלכתיות, השחתררתי
                בדרגת רב סרן ועד עכשיו משרת כקצין הכשרות לוטר (אזרחיות) וצבאיות
                במילואים
              </p>
            </p>
          </div>
        </div>
      </section>

      <section className="about__vision">
        <div className="container about__vision-container">
          <div className="about__section-content">
            <h1> החזון שלי </h1>

            <p>
              החזון שלי לאמן ולהכשיר לוחמים וספורטאים לעם ישראל לרמות אימון
              לתחרויות עד לתחרויות חו"ל והכנה לגיבושים ליחידות מובחרות עד הגעתם
              למקום שהם רוצים להצליח בו, אחוזי הצלחה מעל 99 אחוז כמו כן התקווה
              ,שלי שהילדים המתאמנים אצלי יבנו בטחון עצמי גבוה, משמעת, אחריות
              ולקיחת סיכונים ואתגרים אישיים
            </p>

            <p>
              כמו כן באר שבע ידועה כעיר מעורבת ובה הרבה אתגרים ונוער בסיכון
              החזון שלי לראות נוער צעיר שגודל בעיר ללא פחד ובעלי בטחון עצמי גבוה
            </p>
          </div>
          <div className="about__section-image">
            <img src={VisionImage} alt="Story" />
          </div>
        </div>
      </section>

      <section className="about__mission">
        <div className="container about__mission-container">
          <div className="about__section-image">
            <img src={MissionImage} alt="Mission" />
          </div>

          <div className="about__section-content">
            <h1> המשימה שלי </h1>

            <p>
              המשימה שלי להביא את החניכים שלי <br />
              ואת הלוחמים שלי לדרגות גבוהות אם
              <br /> זה באזרחות ואם בצהל, <br />
              במשפט אחד:
              <br />
              "מדינה קטנה אויבים הרבה" <br /> והמועדון לא רק יבנה ספורטאים,
              המדינה צריכה הרבה לוחמים וזה המטרה שעומדת אל מול עיני
            </p>

            <p>
              שעליתי לארץ בתור נער לא ידעתי לאן הגעתי ומה אני הולך לעשות ללא ידע
              וללא שפה, היו לי הרבה בעיות גם בתחום הלימוד . ו"בלימודים, נער שלא
              יודע לאיפה הוא שייך יכול להיגרר למקומות לא רצויים. בכל זאת תפסתי
              את עצמי בידיים ומתוך "ובגלל" אומניות הלחימה אני קיבלתי את
              המוטיבציה שלא להיגרר לדברים לא נכונים . כמו כן זה מה שנתן לי
              להתגייס ליחידה מובחרת ולהמשיך קריירה צבאית והיום אני יושב ראש
              האיגוד של אקדמיית לאומנייות הלחימה (MFC )
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
