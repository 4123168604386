import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo3.png";
import { FaFacebookF } from "react-icons/fa";
// import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { SiGooglemaps } from "react-icons/si";


const Footer = () => {
  return (
    <footer>
      <div className="container footer__container">
        <article>
          <Link to="/" className="logo">
            <img src={Logo} alt="Footer Logo" />
          </Link>
          <p>
            בית ספר לאגרוף תאילנדי ולאומניות לחימה וקרב מגע, מתאים לכל הגילאים
            בדגש להכנה לתחרויות בארץ ובחוץ לארץ, המדריכים שלנו יוצאי צבא ויחידות
            מיוחדות ובעלי וותק בהדרכת ילדים בלוחמת קרב מגע ואיגרוף תאילנדי
          </p>
          <div className="footer__socials">
            <a
              href="https://www.facebook.com/mixfightisrael"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              <FaFacebookF />{" "}
            </a>
            <a
              href="https://www.instagram.com/mix_fight_israel/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              <AiFillInstagram />{" "}
            </a>

            <a
              style={{
                color: "red",
                background: "black",
              }}
              href="https://mix-fight-israel-2.netlify.app/"
              target="_blank"
              rel="noreferrer noopener"
            >
              לחץ לאימון אישי
            </a>
            <a
              style={{
                color: "red",
                background: "black",
              }}
              href="https://mix-fight-israel-shop.netlify.app"
              target="_blank"
              rel="noreferrer noopener"
            >
              כנס לחנות
            </a>
            <a
              style={{
                color: "red",
                background: "black",
              }}
              href="https://snazzy-dodol-110889.netlify.app/"
              target="_blank"
              rel="noreferrer noopener"
            >
              לחץ כאן לוידאו
            </a>
            <a
              style={{
                color: "red",
                background: "black",
              }}
              href="https://my.atlistmaps.com/map/dfe895d7-70f4-4b0c-8ec6-62cec08491b4?share=true"
              target="_blank"
              rel="noreferrer noopener"
            >
              {/* לחץ כאן למפה */}
              <SiGooglemaps style={{
                height:'3rem',
                width: '3rem'
              }}/>
            </a>
            {/*  */}

            {/* <iframe
        
              style={{
                color: "black",
                background: "black",

                height: "5rem",
                width: "4.5rem",
                borderRadius: "10px",
              }}
              target="_blank"
              rel="noreferrer noopener"
              src="https://my.atlistmaps.com/map/dfe895d7-70f4-4b0c-8ec6-62cec08491b4?share=true" allow="geolocation" width="100%" height="400px" frameborder="0" scrolling="no" allowfullscreen></iframe> */}

            {/* <a href="" target="_blank" rel="noreferrer noopener"></a> */}
          </div>
        </article>
        <article>
          <h4>קישורים </h4>
          <Link to="/about">אודות</Link>
          <Link to="/plans">תוכניות</Link>
          <Link to="/gallery">גלריה</Link>
          <Link to="/Training">אימון אישי</Link>

          <Link to="/contact">צור קשר</Link>
        </article>

        <article>
          <h4>תובנות </h4>
          <Link to="/">בלוג</Link>
          <Link to="/">קרב מגע</Link>
          <Link to="/">אירועים</Link>
          <Link to="/"> קהילות</Link>
          <Link to="/"> שאלות ותשובות</Link>
        </article>
        <article>
          <h4>נהיה בקשר </h4>
          <Link to="/contact">צור איתנו קשר</Link>
          <Link to="/contact">תמיכה</Link>
        </article>
      </div>
      <div className="footer__copyright">
        <small>2022 maty dev &copy; כל הזכויות שמורות </small>
      </div>
    </footer>
  );
};

export default Footer;
