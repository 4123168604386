import { SiOpenaigym } from "react-icons/si";

export const links = [
  {
    name: "בית",
    path: "/",
  },
  {
    name: "אודות",
    path: "/about",
  },
  {
    name: "גלריה",
    path: "/gallery",
  },
  {
    name: "תוכניות",
    path: "/plans",
  },
  // {
  //   name: "וידאו",
  //   path: "/videos",
  // },
  {
    name: "צור קשר",
    path: "/contact",
  },
];

export const programs = [
  {
    id: 1,
    icon: <SiOpenaigym />,
    title: "מסלול ילדים ",
    info: "כלים פיזיים ונפשיים להתמודדות במצבי קיצון ומצוקה כנגד התקפות בריונות ואלימות",
    path: "/plans",
  },
  {
    id: 2,
    icon: <SiOpenaigym />,
    title: "מסלול צעירים ",
    info: "יחס אישי ומקצועי ממאמנים מוכשרים, הגנה עצמית ושיפור הביטחון העצמי",
    path: "/plans",
  },
  {
    id: 3,
    icon: <SiOpenaigym />,
    title: "מסלול מבוגרים ",
    info: "אימוני כושר גופני ואורח חיים ספורטיבי, חיזוק הגוף ושיפור קוארדינציה",
    path: "/plans",
  },
  {
    id: 4,
    icon: <SiOpenaigym />,
    title: "מסלול חדש ",
    info: " הכנה לתחרויות בארץ ובעולם",
    path: "/plans",
  },
];

export const values = [
  {
    id: 1,
    icon: <SiOpenaigym />,
    title: " שינה",
    desc: "ספורטאי זה אדם ששומר על כשירות בעצימות גבוהה ועל כן הוא חייב להקפיד על שינה ומנוחה על מנת לבנות שריר",
  },
  {
    id: 2,
    icon: <SiOpenaigym />,
    title: " שומן",
    desc: "נכון שדיאטת חלבונים חשובה לפיתוח וחיטוב הגוף אך לפעמים מותר לחרוג מהתפריט בצריכת שומנים טבעיים ובריאים",
  },
  {
    id: 3,
    icon: <SiOpenaigym />,
    title: " מים ",
    desc: "צריכת מים חשובה וחיונית לפיתוח הגוף, צריכת מבוגר עומדת על 3.5 ליטר ליום, על מנת למקסם תוצאות חשוב לשמור על שתיית מים",
  },
  {
    id: 4,
    icon: <SiOpenaigym />,
    title: " אופטימיות ",
    desc: "זה הטיפ האחרון והחשוב ביותר. הישארו חיוביים וסבלניים כי התהליך אמנם לא קל אך שווה את זה! עם ההתקדמות בתהליך תוכלו לראות יותר ויותר תוצאות אופטימליות לחיזוק הבטחון העצמי והבריאות שלכם!",
  },
];

export const faqs = [
  {
    id: 1,
    question: "?    באיזו תדירות עלי להתאמן    ",
    answer:
      "מפתח גוף שמרים משקולות סופר-כבדות עשוי לבצע אימון גב ספציפי רק פעם אחת, או לכל היותר, פעמיים בשבוע. אם אתה מבצע תרגילי טיפול קלים יותר בגבך, ייתכן שתעשה את התרגילים כל יום אחר. כאשר המטרה שלך היא להישאר בכושר ופונקציונלי, תרגילי גב פעמיים עד שלוש בשבוע בימים לא רצופים הגיוניים.",
  },
  {
    id: 2,
    question: "  ? באיזו שעה ביום עדיף להתאמן  ",
    answer:
      "אם המטרה שלך היא לרדת במשקל על ידי אימון, בוודאי, אתה רוצה לדעת מהו הזמן הטוב ביותר להתאמן ולשרוף שומן. מחקר שפורסם זה עתה בכתב העת הבינלאומי להשמנת יתר קבע זאת פעילות גופנית בבוקר, לפני 15:00 אחר הצהריים, עוזרת לך לרדת במשקל יותר משעות אחר הצהריים. בנוסף, זה תורם להגברת הפעילות במהלך היום ולאכילת פחות קלוריות.",
  },
  {
    id: 3,
    question: " ? כמה זמן צריכים להיות האימונים שלי",
    answer:
      "הנחת הבסיס היא שעל כל המבוגרים להימנע מחוסר פעילות - תמיד. ולצד זה חשוב לזכור כי קצת פעילות גופנית טובה יותר מאי פעילות כלל. מבוגרים המקפידים על פעילות גופנית שיגרתית זוכים ביתרונות בריאותיים ונפשיים רבים. ",
  },
  {
    id: 4,
    question: "   ?   האם עלי להתחמם לפני האימונים      ",
    answer:
      "החימום מעלה את טמפרטורת הגוף, גורם לכיווץ השרירים ולעלייה בתהליכים המטבוליים. הפעילות מובילה להפחתת מידת צמיגות השריר ובכך לתנועה חלקה יותר שלו. העלייה בטמפרטורה בגוף גורמת גם להיפרדות מהירה של מולקולות חמצן ולאספקת חמצן יעילה וטובה יותר לשרירים.",
  },
  {
    id: 5,
    question: "   ? האם עלי לעשות אימוני כוח, אירובי או שניהם     ",
    answer:
      "אם המטרות של המתאמן הם לשפר את יכולת צריכת החמצן המקסימאלית שלו זה שונה מאשר הרצון לשפר את הכוח של שריריו. לכן, אחד מהדברים עליהם מדריך צריך לשים דגש היא לברר מול המתאמן את מטרותיו על מנת להתאים לו תוכנית אשר תעזור לו למקסם את התוצאות אליהן הוא שואף להגיע.",
  },
  {
    id: 6,
    question: "    ? האם עלי להרים משקולות לאימוני כוח",
    answer:
      "בסקר שבדק את הרגלי הפעילות הגופנית של הישראלים, התגלה שהפעילות המועדפת על רובם היא האירובית, שמטרתה שיפור תפקוד מערכת הלב־ריאה. לעומת זאת, פעילות שכוללת חיזוק שרירים, פופולרית הרבה פחות. רק כרבע מהישראלים שפעילים גופנית מבצעים אימונים אלה: פילאטיס, יוגה, TRX ואימוני משקולות ומכשירים בחדר הכושר. ",
  },
];

export const testimonials = [
  {
    id: 1,
    name: " מקס",
    quote:
      "אחרי שנים של חיפוש עצמי הגעתי לוויטלי, וקיבלתי הדרכה צמודה איך להתאמן נכון וחווית אגרוף ממקצוען אמיתי. ממליץ לכולם",
    job: "",
    avatar: require("./images/w1.jpg"),
  },
  {
    id: 2,
    name: "דני",
    quote:
      "אני מתאמן אצל וויטלי כבר 7 שנים, חווית אימון יוצאת דופן. וויטלי נותן את הנשמה לכל אחד",
    job: "",
    avatar: require("./images/w2.jpg"),
  },
  {
    id: 3,
    name: "קטרין",
    quote:
      "אני מתאמנת במכון של וויטלי מעל 5 שנים ואני מרוצה מאוד משגרת האימונים והיחס האישי של ווויטלי. מומלץ",
    job: "",
    avatar: require("./images/w3.jpg"),
  },
  {
    id: 4,
    name: "דור",
    quote:
      "אני מתאמן אצל וויטלי 11 שנה, מדריך אישי ומסור נותן את הנשמה לכולם, רק אצלו מגיעים לתוצאות מרשימות",
    job: "",
    avatar: require("./images/w4.jpg"),
  },
  {
    id: 5,
    name: "אמיר",
    quote:
      "שש שנים של מהפך, מהאימון הראשון שלי אצל וויטלי הרגשתי שזה משהו אחר חווית אימון סוחפת ובונה, אני ממליץ",
    job: "",
    avatar: require("./images/w6.jpg"),
  },
];

export const plans = [
  {
    id: 1,
    name: "תוכנית ילדים",
    desc: "חבילה זו מושלמת למתחילים שזקוקים לעזרה מתמדת",
    price: "ילדים",
    features: [
      { feature: "איגרוף תאילנדי", available: true },
      { feature: "איגרוף קלאסי", available: true },
      { feature: "היאבקות חופשית", available: true },
      { feature: "MMA לחימה משולבת", available: true },
      { feature: "18:00-19:00", available: true },
      { feature: "יום ראשון איגרוף קלאסי   ", available: true },
      { feature: "יום שלישי איגרוף תאילנדי ", available: true },
      { feature: "יום חמישי לחימה משולבת ", available: true },
      { feature: "קרב מגע  ", available: true },
      { feature: "אימון אישי ", available: true },
      { feature: "אימון קבוצתי ", available: true },
      { feature: "הגנה עצמית ", available: true },
      { feature: "סדנאות ", available: true },
    ],
  },
  {
    id: 2,
    name: "תוכנית נוער",
    desc: "זוהי החבילה המושלמת למתאמנים בוגרים שיודעים מה הם עושים",
    price: "נוער",
    features: [
      { feature: "איגרוף תאילנדי", available: true },
      { feature: "איגרוף קלאסי", available: true },
      { feature: "היאבקות חופשית", available: true },
      { feature: "MMA לחימה משולבת", available: true },
      { feature: "19:00-20:00 ", available: true },
      { feature: "יום ראשון איגרוף קלאסי   ", available: true },
      { feature: "יום שלישי איגרוף תאילנדי ", available: true },
      { feature: "יום חמישי לחימה משולבת ", available: true },
      { feature: "קרב מגע  ", available: true },
      { feature: "אימון אישי ", available: true },
      { feature: "אימון קבוצתי ", available: true },
      { feature: "הגנה עצמית ", available: true },
      { feature: "סדנאות ", available: true },
    ],
  },
  {
    id: 3,
    name: "תוכנית מבוגרים",
    desc: "חבילה זו מושלמת לאנשים מבוגרים  ",
    price: "בוגרים",
    features: [
      { feature: "איגרוף תאילנדי", available: true },
      { feature: "איגרוף קלאסי", available: true },
      { feature: "היאבקות חופשית", available: true },
      { feature: "MMA לחימה משולבת", available: true },
      { feature: "20:00-21:30", available: true },
      { feature: "יום ראשון איגרוף קלאסי   ", available: true },
      { feature: "יום שלישי איגרוף תאילנדי ", available: true },
      { feature: "יום חמישי לחימה משולבת ", available: true },
      { feature: "יום חמישי לחימה משולבת ", available: true },
      { feature: "קרב מגע  ", available: true },
      { feature: "אימון אישי ", available: true },
      { feature: "אימון קבוצתי ", available: true },
      { feature: "הגנה עצמית ", available: true },
      { feature: "סדנאות ", available: true },
    ],
  },
  {
    id: 4,
    name: "תוכנית אימונים לתחרויות",
    desc: "חבילה זו מושלמת למתאמנים וותיקים   ",
    price: "תחרויות",
    features: [
      { feature: "איגרוף תאילנדי", available: true },
      { feature: "איגרוף קלאסי", available: true },
      { feature: "היאבקות חופשית", available: true },
      { feature: "MMA לחימה משולבת", available: true },
      { feature: "אימון אישי ", available: true },
      { feature: "בניית אימון ממוקד  ", available: true },
      { feature: "ליווי פרטני", available: true },
      { feature: "אימון משולב", available: true },
      { feature: "המלצות ", available: true },
      { feature: "ניסיון של שנים", available: true },
      { feature: "אימון קבוצתי", available: true },
      { feature: "שעות נוחות", available: true },
      { feature: "אימון חוויתי", available: true },
      { feature: " תחרויות בארץ ובחול", available: true },
    ],
  },
];

const Trainer1 = require("./images/trainer1.jpg");
const Trainer2 = require("./images/trainer2.jpg");
const Trainer3 = require("./images/trainer3.jpg");
const Trainer4 = require("./images/trainer4.jpg");
const Trainer5 = require("./images/trainer5.jpg");
const Trainer6 = require("./images/trainer6.jpg");

export const trainers = [
  {
    id: 1,
    image: Trainer1,
    name: "John Doe",
    job: "Aerobic Trainer",
    socials: [
      "https://instagram.com/",
      "https://twitter.com/",
      "https://facebook.com/",
      "https://linkedin.com/",
    ],
  },
  {
    id: 2,
    image: Trainer2,
    name: "Daniel vinyo",
    job: "Speed Trainer",
    socials: [
      "https://instagram.com/",
      "https://twitter.com/",
      "https://facebook.com/",
      "https://linkedin.com/",
    ],
  },
  {
    id: 3,
    image: Trainer3,
    name: "Edem Quist",
    job: "Flexibility Trainer",
    socials: [
      "https://instagram.com/",
      "https://twitter.com/",
      "https://facebook.com/",
      "https://linkedin.com/",
    ],
  },
  {
    id: 4,
    image: Trainer4,
    name: "Shatta Wale",
    job: "Body Composition Trainer",
    socials: [
      "https://instagram.com/",
      "https://twitter.com/",
      "https://facebook.com/",
      "https://linkedin.com/",
    ],
  },
  {
    id: 5,
    image: Trainer5,
    name: "Diana Ayi",
    job: "Circuit Trainer",
    socials: [
      "https://instagram.com/",
      "https://twitter.com/",
      "https://facebook.com/",
      "https://linkedin.com/",
    ],
  },
  {
    id: 6,
    image: Trainer6,
    name: "Wayne Carter",
    job: "Physical Intelligence Trainer",
    socials: [
      "https://instagram.com/",
      "https://twitter.com/",
      "https://facebook.com/",
      "https://linkedin.com/",
    ],
  },
];
